import React from 'react';

import data from '../data';
import { H1, Copy } from 'Styles/typography';
import Segment from 'Components/segment';
import Row from 'Components/row';
import Column from 'Components/column';
import Button from 'Components/button';

const Four0Four = () => {
  const { ['404']: page } = data;

  return (
    <Segment paddingTop="120" paddingBottom="180" paddingTopMobile="50" paddingBottomMobile="50">
      <Row>
        <Column all={{ width: 20 }} custom={[{ bp: 1025, width: 18, offsetLeft: 1 }]}>
          <H1 marginBottom="30">{page.heading}</H1>
          <Copy>{page.copy}</Copy>

          <div style={{ marginTop: '30px' }}>
            <Button variant="primary" size="large" href="/">
              Back to home
            </Button>
          </div>
        </Column>
      </Row>
    </Segment>
  );
};

export default Four0Four;
